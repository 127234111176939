<template>
  <section class="page-other-transaction-deduction-add">
    <page-header
      :back-to="{ name: 'other-transaction.deduction' }"
      title="Tambah Data Transaksi Lainnya"
      class="mb-32"
    />

    <bg-grid>
      <bg-grid-item :col="12">
        <bg-field
          class="mb-16"
          label="Jenis Data Transaksi"
          description="Denda & Penalti"
        />

        <bg-divider />
      </bg-grid-item>

      <bg-grid-item :col="6">
        <bg-field label="Nama Properti">
          <bg-skeleton
            v-if="isLoading.page"
            width="100%"
            height="48px"
            data-testid="loader"
          />
          <bg-search-bar
            v-else
            v-model="value.propertyName"
            placeholder="Pilih properti"
          />
        </bg-field>
      </bg-grid-item>

      <bg-grid-item :col="12">
        <div class="deductions-container">
          <bg-grid>
            <bg-grid-item :col="6">
              <bg-field class="mb-0" label="Kategori Penalti">
                <bg-select
                  v-model="value.category"
                  :options="options.category"
                />
              </bg-field>
            </bg-grid-item>
            <bg-grid-item :col="6">
              <bg-field class="mb-0" label="Status Masa Percobaan">
                <bg-select
                  v-model="value.probation"
                  :options="options.probation"
                />
              </bg-field>
            </bg-grid-item>

            <bg-grid-item :col="12">
              <deduction-item
                v-for="(deduction, index) in value.deductions"
                :deduction="deduction"
                :index="index"
                :options="options"
                :key="`deduction-${deduction.type}-${index}`"
                :disable-delete="disabled.deleteFeeButton"
                @select-fee-type="selectFeeType($event, index)"
                @add-another-fee="addAnotherFee"
                @on-type-in="onTypeIn($event, index)"
                @remove-deduction="removeDeduction(index)"
              />

              <div>
                <bg-button
                  variant="secondary"
                  :disabled="disabled.addAnotherFeeButton"
                  @click="addAnotherFee"
                  >Tambah Pengurangan</bg-button
                >
              </div>
            </bg-grid-item>
          </bg-grid>
        </div>
      </bg-grid-item>

      <bg-grid-item :col="12">
        <bg-field label="Total Pengurangan" :description="deductionTotal" />
        <bg-button
          variant="primary"
          :disabled="disabled.submitButton"
          @click="showModal.submit = true"
          >Tambah Data</bg-button
        >
      </bg-grid-item>
    </bg-grid>

    <bg-modal
      v-model="showModal.submit"
      desktop-size="sm"
      title="Yakin ingin tambah data ini?"
      description="Data yang ditambahkan akan tercatat pada Laporan Transfer Pendapatan"
      button-main-text="Tambah"
      button-second-text="Batal"
      @click-second-button="showModal.submit = false"
    />
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgField,
  BgSelect,
  BgDivider,
  BgSkeleton,
  BgSearchBar,
  BgButton,
  BgModal,
} from 'bangul-vue';
import { rupiahFormatter } from 'Utils/formatter';

const CATEGORY_NAME = {
  recoupment: 'recoupment',
  termination: 'termination',
  'return-attributes': 'returnAttributes',
  'undertable-income': 'undertableIncome',
  'undertable-commission': 'undertableCommission',
  'other-fee': 'otherFee',
};

export default {
  name: 'AddDeduction',

  components: {
    BgGrid,
    BgGridItem,
    BgField,
    BgSelect,
    BgDivider,
    BgSkeleton,
    BgSearchBar,
    BgButton,
    // eslint-disable-next-line vue/no-unused-components
    BgModal,
    PageHeader: () => import('@admin_molecules/PageHeader'),
    DeductionItem: () =>
      import(
        '@admin/pages/OtherTransaction/EarningDeduction/_components/DeductionItem'
      ),
  },

  data() {
    return {
      isLoading: {
        page: false,
      },

      value: {
        propertyName: '',
        category: '',
        probation: '',

        deductions: [
          /* Value example for each fee type:
						{
							feeType: "undertable-commission",
							value: {
								roomType: "B",
								roomNumber: "102",
								tenantName: "asdf",
								price: 10002,
								valid: true
							}
						},
					*/
          {
            feeType: '',
            value: {},
          },
        ],
      },

      options: {
        category: [
          {
            val: '1',
            label: 'Cidera janji/lalai',
          },
          {
            val: '2',
            label: 'Penerimaan pembayaran di bawah tangan (undertable)',
          },
          {
            val: '3',
            label:
              'Penerimaan pembayaran di bawah tangan (undertable) diikuti pengakhiran',
          },
          {
            val: '4',
            label:
              'Pengakhiran karena okupansi rendah (ada penyewa dari Mamikos)',
          },
          {
            val: '5',
            label:
              'Pengakhiran karena okupansi rendah (tidak ada penyewa dari Mamikos)',
          },
          {
            val: '6',
            label:
              'Pengakhiran lebih awal oleh pemilik (ada penyewa dari Mamikos)',
          },
          {
            val: '7',
            label:
              'Pengakhiran lebih awal oleh pemilik (tidak ada penyewa dari Mamikos)',
          },
        ],
        probation: [
          {
            val: '1',
            label: 'Selama masa percobaan',
          },
          {
            val: '2',
            label: 'Setelah masa percobaan',
          },
        ],

        feeType: [
          {
            val: 'recoupment',
            label: 'Biaya ganti rugi',
            disabled: true,
          },
          {
            val: 'termination',
            label: 'Biaya pengakhiran',
            disabled: true,
          },
          {
            val: 'return-attributes',
            label: 'Biaya pengembalian atribut/branding ',
            disabled: true,
          },
          {
            val: 'undertable-income',
            label: 'Biaya atas penerimaan di bawah tangan/undertable',
            disabled: true,
          },
          {
            val: 'undertable-commission',
            label: 'Potongan atas pembayaran undertable untuk komisi Mamikos',
          },
          {
            val: 'other-fee',
            label: 'Biaya lain',
            disabled: true,
          },
        ],

        undertableCommission: {
          roomType: [
            {
              val: 'A',
              label: 'A',
              price: 10001,
            },
            {
              val: 'B',
              label: 'B',
              price: 10002,
            },
            {
              val: 'C',
              label: 'C',
              price: 10003,
            },
          ],
          roomNumber: [
            {
              val: '101',
              label: '101',
              disabled: false,
            },
            {
              val: '102',
              label: '102',
              disabled: false,
            },
            {
              val: '103',
              label: '103',
              disabled: false,
            },
            {
              val: '104',
              label: '104',
              disabled: false,
            },
            {
              val: '105',
              label: '105',
              disabled: false,
            },
          ],
        },
      },

      showModal: {
        submit: false,
      },
    };
  },

  computed: {
    allIsValid() {
      return this.value.deductions.reduce(
        (accumulator, value) => accumulator && value.value.valid,
        true
      );
    },
    deductionTotal() {
      const total = this.value.deductions.reduce(
        (accumulator, value) => accumulator + value.value.price,
        0
      );

      return total > 0 ? this.rupiahFormatter(total) : '-';
    },
    disabled() {
      return {
        addAnotherFeeButton: !this.allIsValid,
        deleteFeeButton: this.value.deductions.length < 2,
        submitButton: !(
          this.allIsValid &&
          this.value.propertyName &&
          this.value.category &&
          this.value.probation
        ),
      };
    },
    roomNumber() {
      return this.options.undertableCommission.roomNumber;
    },
    categorized() {
      const categorized = {
        recoupment: [],
        termination: [],
        returnAttributes: [],
        undertableIncome: [],
        undertableCommission: [],
        otherFee: [],
      };

      this.value.deductions.forEach(item => {
        if (item.feeType) {
          categorized[CATEGORY_NAME[item.feeType]].push(item.value);
        }
      });

      return categorized;
    },
  },

  watch: {
    'categorized.undertableCommission': {
      handler(newValue, oldValue) {
        newValue.forEach(item => {
          if (item.roomNumber) {
            this.options.undertableCommission.roomNumber.forEach(e => {
              e.disabled = false;
            });
            const { roomNumber } = item;
            const index = this.roomNumber.findIndex(e => e.val === roomNumber);
            this.roomNumber[index].disabled = true;
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    selectFeeType(val, index) {
      this.value.deductions[index].feeType = val;
    },
    addAnotherFee() {
      this.value.deductions.push({ feeType: '', value: {} });
    },
    onTypeIn(value, index) {
      this.value.deductions[index].value = value;
    },
    rupiahFormatter(value) {
      return rupiahFormatter(value);
    },
    removeDeduction(index) {
      this.value.deductions.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-other-transaction-deduction-add {
  padding: 0 16px;
}

.deductions-container {
  background-color: #fafafa;
  padding: 16px;
}
</style>
